<template>
  <div
    class="cell-star"
    @click="toStarPage"
  >
    <van-checkbox
      class="checkbox"
      v-model="checked"
      shape="square"
      v-if="editCheck"
      icon-size="15px"
    >
      &nbsp;&nbsp;
    </van-checkbox>
    <img
      class="avatar"
      :src="data && data.avatar ? data.avatar:require('@/assets/images/icon-avatar@2x.png')"
    >
    <div class="common-info">
      <div>
        <span class="nickname">{{ data && data.nickname ? data.nickname : '-' }}</span>
        <img
          class="icon-gender"
          src="@/assets/images/icon-male@2x.png"
          v-if="data && data.gender === 1"
        >
        <img
          class="icon-gender"
          src="@/assets/images/icon-female@2x.png"
          v-else-if="data && data.gender === 2"
        >
      </div>
      <div class="brief">
        <span>ID: {{ data ? (data.short_id ? data.short_id : data.id) : '-' }}</span>
        <span class="mcn">MCN：{{ data && data.mcn && data.mcn.name ? data.mcn.name : '-' }}</span>
      </div>
    </div>
    <div
      class="arrow"
      v-if="!editCheck"
    />
  </div>
</template>

<script>
export default {
  name: 'BoundCellStarInfo',
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
    editCheck: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      checked: false
    };
  },
  watch: {
    editCheck(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.checked = false;
      }
    }
  },
  methods: {
    toStarPage() {
      if (this.editCheck) {
        return;
      }
      console.log('to star page');
      this.$emit('toStarDetail', this.data);
    }
  },
};
</script>

<style lang="less" scoped>
.cell-star {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  margin: 0 30px;
  background: #F9F9F9;
  border-radius: 8px;
  cursor: pointer;

  .checkbox {
    margin-left: 30px;
    margin-right: -30px;
  }

  .avatar {
    flex-shrink: 0;
    width: 108px;
    height: 108px;
    border-radius: 60px;
    margin-left: 40px;
  }

  .common-info {
    width: 100%;
    margin-left: 30px;

    .nickname {
      font-size: 32px;
      font-weight: 600;
      color: #333333;
      line-height: 32px;
    }

    .icon-gender {
      width: 26px;
      height: 26px;
      margin-left: 10px;
    }

    .brief {
      font-size: 24px;
      font-weight: 400;
      color: #999;
      line-height: 32px;
      margin-top: 20px;
    }

    .mcn {
      margin-left: 20px;
    }
  }

  .arrow {
    margin-right: 20px;
    width: 16px;
    height: 28px;
    background-image: url("~@/assets/images/icon-more-grey@2x.png");
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;
  }

  &:active .arrow {
    background-image: url("~@/assets/images/icon-more-blue@2x.png");
  }

}
</style>