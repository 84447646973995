<template>
  <div
    class="cell-mcn"
    @click="toMcnPage"
  >
    <van-checkbox
      class="checkbox"
      v-model="checked"
      shape="square"
      v-if="editCheck"
      icon-size="15px"
    >
      &nbsp;&nbsp;
    </van-checkbox>
    <img
      class="avatar"
      src="@/assets/images/icon-mcn@2x.png"
    >
    <div class="middle">
      <span class="title">{{ data && data.name ? data.name : '-' }}</span>
    </div>
    <div
      class="arrow"
      v-if="!editCheck"
    />
  </div>
</template>

<script>
export default {
  name: 'BoundCellMcn',
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
    editCheck: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      checked: false
    };
  },
  watch: {
    editCheck(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.checked = false;
      }
    }
  },
  methods: {
    toMcnPage() {
      if (this.editCheck) {
        return;
      }
      console.log('to mcn page');
      this.$emit('toMcnDetail', this.data);
    }
  },
};
</script>

<style lang="less" scoped>
.cell-mcn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  margin: 0 30px;
  background: #EFF8FF;
  border-radius: 8px;
  cursor: pointer;

  .checkbox {
    margin-left: 30px;
    margin-right: -30px;
  }

  .avatar {
    flex-shrink: 0;
    width: 108px;
    height: 108px;
    border-radius: 60px;
    margin-left: 40px;
  }

  .middle {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .title {
      font-size: 32px;
      font-weight: 600;
      color: #333;
      line-height: 32px;
      margin-left: 30px;
    }
  }


  .arrow {
    margin-right: 20px;
    width: 16px;
    height: 28px;
    background-image: url("~@/assets/images/icon-more-grey@2x.png");
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;
  }

  &:active .arrow{
    background-image: url("~@/assets/images/icon-more-blue@2x.png");
  }
}
</style>