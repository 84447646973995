<template>
  <div
    id="bounds-page"
    class="bounds-page"
  >
    <section-text text="该客户/群已绑定以下机构和达人" />
    <div>
      <bound-cell-mcn
        v-for="mcn in mcns"
        class="cell"
        :ref="'mcn_'+mcn.id"
        :key="mcn.id"
        :data="mcn"
        :editCheck="unbinding"
        @toMcnDetail="handleToMcnDetail"
      />
      <bound-cell-star-info
        v-for="star in stars"
        class="cell"
        :ref="'star_'+star.id"
        :key="star.id"
        :data="star"
        :editCheck="unbinding"
        @toStarDetail="handleToStarDetail"
      />
      <div
        class="bottom-placeholder"
        v-if="hasPermission"
      />
    </div>
    <div
      class="bottom"
      :style="'width:'+bottomWidth+'px;'"
      v-if="hasPermission"
    >
      <van-button
        class="btn-unbound"
        @click="unbinding=!unbinding"
      >
        {{ unbinding ? '取消' : '解绑' }}
      </van-button>
      <van-button
        class="btn-add-bind"
        v-if="!unbinding"
        @click="toAddBind"
      >
        新增绑定
      </van-button>
      <van-button
        class="btn-confirm"
        v-else
        :loading="unbindLoading"
        @click="confirmUnbound"
      >
        确定解绑
      </van-button>
    </div>


    <van-action-sheet
      v-model:show="showAddBindType"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="handleSelectAddBind"
    />
  </div>
</template>

<script>
import SectionText from '@/components/SectionText';
import BoundCellMcn from './components/BoundCellMcn';
import BoundCellStarInfo from './components/BoundCellStarInfo';
import { Dialog, Toast } from 'vant';
import { mapActions } from 'vuex';

export default {
  name: 'Bounds',
  components: {
    BoundCellMcn,
    BoundCellStarInfo,
    SectionText,
  },
  props: {
    session: {
      type: Object,
      default() {
        return null;
      },
    },
    stars: {
      type: Array,
      default() {
        return [];
      },
    },
    mcns: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      bottomWidth: 0,
      unbinding: false,
      hasPermission: true,
      unbindLoading: false,
      showAddBindType: false,
      actions:  [
        { name: '绑定达人' },
        { name: '绑定MCN机构' },
      ]
    };
  },
  created() {
    this.$nextTick(function () {
      const page_el = document.getElementById('bounds-page');
      if (page_el) {
        this.bottomWidth = page_el.offsetWidth;
      }
    });
  },
  methods: {
    confirmUnbound() {
      const mcnsId = [];
      this.mcns.forEach((x)=>{
        if (this.$refs['mcn_' + x.id][0].checked) {
          mcnsId.push(x.id)
        }
      })
      const starsId = [];
      this.stars.forEach((x)=>{
        if (this.$refs['star_' + x.id][0].checked) {
          starsId.push(x.id)
        }
      })
      if (mcnsId.length === 0 && starsId.length === 0) {
        Toast.fail('请先选择解绑的MCN机构或者达人');
        return;
      }
      Dialog.confirm({
        title: '确认解绑',
        message:
          '是否解除绑定选中的达人或机构？',
      })
        .then(async () => {
          console.log('confirm');
          this.unbindLoading = true;
          console.log('unbind')
          const res = await this.unbind(
            { id: this.session.id, mcnsId, starsId }
          ).catch((rej) => {
            this.unbindLoading = false;
          });
          console.log('unbind end')
          if (res) {
            Toast.success('解除绑定成功');
            this.$emit('unbindSuccess');
          }
          this.unbindLoading = false;
          this.unbinding = false;
        })
        .catch((e) => {
          console.log(e);
          console.log('cancel');
        });
    },
    toAddBind() {
      this.showAddBindType = true;
    },
    handleSelectAddBind(action, index) {
      if(!this.session || !this.session.id){
        Toast.fail({
          message: '会话数据异常,请刷新后重试',
        });
        return
      }
      if (index === 0) {
        this.$router.push({
          name: 'BindingSessionStar',
          params: {
            id: this.session.id,
          },
        });
      } else if (index === 1) {
        this.$router.push({
          name: 'BindingSessionMcn',
          params: {
            id: this.session.id,
          },
        });
      }
    },
    ...mapActions({
      unbind: 'session/unbind',
    }),
    handleToStarDetail(data){
      this.$emit('toStarDetail', data);
    },
    handleToMcnDetail(data){
      this.$emit('toMcnDetail', data);
    },
  }
};
</script>

<style lang="less" scoped>
.bounds-page {
  background: #fff;

  .cell {
    margin-bottom: 20px;
  }

  .bottom {
    display: flex;
    align-items: center;
    background: #fff;
    height: 160px;
    border-top: 1px solid #E6E6E6;
    position: fixed;
    bottom: 0;

    .btn-unbound {
      width: 30%;
      background: #FFFFFF;
      border-radius: 40px;
      border: 1px solid #0095FF;
      font-size: 30px;
      font-weight: 600;
      color: #0095FF;
      line-height: 30px;
      margin-left: 30px;

      &:active {
        opacity: 0.8;
      }
    }
    .btn-confirm,
    .btn-add-bind {
      width: 70%;
      background: #0095FF;
      border-radius: 40px;
      font-size: 30px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 30px;
      margin-left: 10px;
      margin-right: 30px;

      &:active {
        opacity: 0.8;
      }
    }
  }

  .bottom-placeholder {
    height: 160px;
  }
}
</style>